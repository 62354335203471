var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.customerGroupList.length)?_c('v-card',[_c('v-card-title',[_c('v-col',{staticClass:"text-right p-0"}),_c('div',{staticClass:"d-block w-100 mb-2"},[_c('rs-return-to')],1)],1),_c('v-card-text',[(_vm.customerGroupList.length > 1)?_c('rs-select-customer-group',{staticClass:"mb-10",attrs:{"list":_vm.customerGroupList},on:{"change":_vm.loadStatus},model:{value:(_vm.customerGroupId),callback:function ($$v) {_vm.customerGroupId=$$v},expression:"customerGroupId"}}):_vm._e(),_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"to":"#info","replace":""}},[_vm._v("Genel")]),_c('v-tab',{attrs:{"to":"#payment","replace":""}},[_vm._v("Ödeme Yap")])],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{staticClass:"pa-4",attrs:{"value":"info"}},_vm._l((_vm.statusList),function(statusItem){return _c('div',{key:statusItem.customer.id,staticClass:"mb-10"},[(_vm.statusList.length > 1)?_c('h3',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(statusItem.customer.title)+" ")]):_vm._e(),_c('v-simple-table',{scopedSlots:_vm._u([(_vm.statusList.length > 0)?{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"20vw"}},[_vm._v("İşlem")]),_c('th',{staticStyle:{"width":"20vw"}},[_vm._v("Tarih")]),_c('th',[_vm._v("Tutar")])])]),_c('tfoot',[_c('tr',[_c('td',{staticStyle:{"width":"20vw"},attrs:{"colspan":"2"}},[_vm._v("Toplam")]),_c('td',{staticClass:"text-end"},[(
                        statusItem.payment_total - statusItem.invoice_total <
                        0
                      )?_c('rs-action',{staticClass:"me-3",attrs:{"to":{
                        name: 'my-customer.billing.list',
                        hash: '#payment',
                      }}},[_vm._v(" Ödeme Yap ")]):_vm._e(),_c('rs-table-cell-number',{attrs:{"price":"","colored":"","value":statusItem.payment_total - statusItem.invoice_total}})],1)])]),_c('tbody',_vm._l((statusItem.transactions),function(transaction){return _c('tr',{key:transaction.id + transaction.type},[(transaction.type === 'invoice')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'my-customer.invoices.show',
                        params: {
                          id: transaction.id,
                        },
                      }}},[_vm._v(" Fatura ")]),_c('a',{attrs:{"href":"#","title":"Faturayı kaydet"},on:{"click":function($event){$event.preventDefault();return _vm.handleDownloadInvoiceClick(transaction)}}},[_c('v-icon',[_vm._v("mdi-file-download-outline")])],1)],1):_c('td',[_vm._v("Ödeme")]),_c('td',[_c('rs-table-cell-date',{attrs:{"value":transaction.date}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","colored":"","value":transaction.amount}})],1)])}),0)]},proxy:true}:null],null,true)})],1)}),0),_c('v-tab-item',{staticClass:"pa-4",attrs:{"value":"payment"}},[_c('h2',{staticClass:"mb-5"},[_vm._v("Ödeme Bilgileri")]),_c('p',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("Finansbank")])]),_c('p',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("Robo Yapay Zeka Yazılım A.Ş.")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleCopy('Robo Yapay Zeka Yazılım A.Ş.')}}},[_c('v-icon',[_vm._v("mdi-clipboard-file")])],1)]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" TR23 0011 1000 0000 0126 1979 67 "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleCopy('TR23 0011 1000 0000 0126 1979 67')}}},[_c('v-icon',[_vm._v("mdi-clipboard-file")])],1)])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }