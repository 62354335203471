<template>
  <v-card v-if="customerGroupList.length">
    <v-card-title>
      <!-- <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Müşteri</small><br />
          <span>{{ originalData.name }}</span>
        </div>
      </h3> -->
      <v-col class="text-right p-0">
        <!-- <rs-action
          @click="showActionLogs"
          v-if="id"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action> -->
        <!-- <rs-action
          :href="
            'https://uygulama.parasut.com/416690/musteriler/' +
            originalData.accounting_id
          "
          target="_blank"
          v-if="id && originalData.accounting_id"
          icon
          title="Paraşüt'te göster"
        >
          P
        </rs-action> -->
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <rs-select-customer-group
        :list="customerGroupList"
        v-if="customerGroupList.length > 1"
        v-model="customerGroupId"
        @change="loadStatus"
        class="mb-10"
      />

      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Genel</v-tab>
        <v-tab to="#payment" replace>Ödeme Yap</v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <div
            v-for="statusItem in statusList"
            :key="statusItem.customer.id"
            class="mb-10"
          >
            <h3 class="text-secondary" v-if="statusList.length > 1">
              {{ statusItem.customer.title }}
            </h3>
            <v-simple-table>
              <template v-slot:default v-if="statusList.length > 0">
                <thead>
                  <tr>
                    <th style="width: 20vw">İşlem</th>
                    <th style="width: 20vw">Tarih</th>
                    <th>Tutar</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td style="width: 20vw" colspan="2">Toplam</td>
                    <td class="text-end">
                      <rs-action
                        class="me-3"
                        :to="{
                          name: 'my-customer.billing.list',
                          hash: '#payment',
                        }"
                        v-if="
                          statusItem.payment_total - statusItem.invoice_total <
                          0
                        "
                      >
                        Ödeme Yap
                      </rs-action>
                      <rs-table-cell-number
                        price
                        colored
                        :value="
                          statusItem.payment_total - statusItem.invoice_total
                        "
                      />
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr
                    v-for="transaction in statusItem.transactions"
                    :key="transaction.id + transaction.type"
                  >
                    <td v-if="transaction.type === 'invoice'">
                      <router-link
                        :to="{
                          name: 'my-customer.invoices.show',
                          params: {
                            id: transaction.id,
                          },
                        }"
                      >
                        Fatura
                      </router-link>
                      <a
                        href="#"
                        @click.prevent="handleDownloadInvoiceClick(transaction)"
                        title="Faturayı kaydet"
                      >
                        <v-icon>mdi-file-download-outline</v-icon>
                      </a>
                    </td>
                    <td v-else>Ödeme</td>
                    <td>
                      <rs-table-cell-date :value="transaction.date" />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number
                        price
                        colored
                        :value="transaction.amount"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-tab-item>
        <v-tab-item value="payment" class="pa-4">
          <h2 class="mb-5">Ödeme Bilgileri</h2>
          <p style="font-size: 18px"><strong>Finansbank</strong></p>
          <p style="font-size: 18px">
            <strong>Robo Yapay Zeka Yazılım A.Ş.</strong>
            <a
              href="#"
              @click.prevent="handleCopy('Robo Yapay Zeka Yazılım A.Ş.')"
              ><v-icon>mdi-clipboard-file</v-icon></a
            >
          </p>
          <p style="font-size: 16px">
            TR23 0011 1000 0000 0126 1979 67
            <a
              href="#"
              @click.prevent="handleCopy('TR23 0011 1000 0000 0126 1979 67')"
              ><v-icon>mdi-clipboard-file</v-icon></a
            >
          </p>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
// import { debounce } from "@/core/plugins/debounce";
import { hasForm, hasPermissions, isPage } from "@/view/mixins";

export default {
  name: "CustomerInvoiceList",
  mixins: [hasForm, hasPermissions, isPage],
  data() {
    return {
      pageMeta: {
        title: "Ödemeler",
      },
      customerGroupList: [],
      customerGroupId: null,
      statusList: [],
      selectedTab: "info",
    };
  },
  mounted() {
    // this.generateExportColumns(this.headers);
    this.loadCustomerGroupList();
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadCustomerGroupList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .query("customers/mine")
        .then((response) => {
          this.customerGroupList = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false))
        .then(() => {
          if (this.customerGroupList.length === 1) {
            this.customerGroupId = this.customerGroupList[0].id;

            return this.loadStatus();
          }
        });
    },
    loadStatus() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .query(`customer-groups/${this.customerGroupId}/status`)
        .then((response) => {
          this.statusList = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadInvoiceClick(transaction) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .query(`customer-invoices/${transaction.id}/document`)
        .then((response) => {
          if (response.data.data.url) {
            const anchor = document.createElement("a");
            anchor.href = response.data.data.url;
            anchor.target = "_blank";
            anchor.click();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCopy(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.info("Kopyalandı: " + text);
      });
    },
    // loadList() {
    //   if (this.isLoading) {
    //     return;
    //   }

    //   if (this.customerGroupList.length === 0) {
    //     return this.loadCustomerGroupList();
    //   }

    //   this.isLoading = true;
    //   this.list = [];
    //   this.footTotals = [];

    //   const params = this.getParams();

    //   this.$api
    //     .query("customers/mine", { params })
    //     .then((response) => {
    //       this.loadListFromResponse(response);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     })
    //     .finally(() => (this.isLoading = false));
    // },
  },
  // watch: {
  //   options: {
  //     handler() {
  //       this.setURLParams();
  //     },
  //     deep: true,
  //   },
  //   search: {
  //     handler: debounce(function () {
  //       this.options.page = 1;
  //       this.setURLParams();
  //     }, 250),
  //     deep: true,
  //   },
  // },
};
</script>
